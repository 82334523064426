<template>
<div >
    <transition name="slide">
    <router-view></router-view>
  </transition>
</div>
</template>

<script>
export default {
    
}
</script>

